/* Reset some basic styles */
* {
  margin: 0;
  padding: 0;
  /* box-sizing: border-box; */
}

body {
  /* font-family: 'ff-meta-serif-web-pro', serif; */
  background-color: #f4f4f9;
  color: #333;
  line-height: 1.6;
  /* padding: 20px; */
}

.blog-post {
  display: flex; /* Use flexbox to align image and content side by side */
  align-items: center; /* Center items vertically */
  margin-bottom: 20px; /* Space below each blog post */
}


.blog-post-image{
  width: 200px;
  align-content: center;
  text-align: center;
  align-items: center;

}

/* section{
  padding: 20px 0px;
} */

h2{
  text-align: center;
}

h2, h3 {
  font-family: 'freight-sans-pro', sans-serif;
  color: #333;
  margin: 20px 0;
}

ul {
  margin: 20px 0;
  padding-left: 20px;
}

ul li {
  margin-bottom: 10px;
}

p {
  margin-bottom: 20px;
}

.content-wrapper {
  max-width: 800px;
  margin: 0 auto;
  padding: 20px;
  background-color: white;
  /* box-shadow: 0 0 10px rgba(0, 0, 0, 0.1); */
}

.responsive-image{
  max-width: 100%;
  text-align: center;
  align-items: center;
  align-content: center;
  justify-content: center;
}

.image-wrapper {
  display: flex; /* Use flexbox to align content */
  justify-content: center; /* Center image horizontally */
  align-items: center; /* Center image vertically */
}


.image-section {
  display: flex;
  justify-content: space-between;
  margin: 20px 0;
  /* align-items: center; */

}

.left, .right{
  flex: 1; /* Allow each image container to take equal space */
  justify-content: center;
  text-align: center;
  /* align-content: center; */
}

.rounded-image {
  width: 180px;
  height: 180px;
  border-radius: 50%; /* Circular images */
  box-shadow: 0 2px 5px rgba(0, 0, 0, 0.3);
  align-content: center;
}

@media screen and (max-width: 768px) {
  .image-section {
      flex-direction: column;
      align-items: center;
      width: 100%;
  }

  .rounded-image {
      margin-bottom: 20px;
  }
}
