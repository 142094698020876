/* src/components/Navbar/Navbar.css */
.navbar {
    display: flex;
    align-items: center;
    justify-content: space-between;
    padding: 1  0px;
    background-color: #333;
    color: white;
    /* margin-bottom: 20px; */
}

.logo {
    margin-right: 40px;
    height: 50px;
    fill: white; /* Set the fill color to white */
}

.nav-links {
    display: flex;
    margin: 0;
    padding: 0;
    align-items: center; /* Center the text vertically */
}

.nav-links a {
    color: white;
    text-decoration: none;
}

