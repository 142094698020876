/* src/components/Home/Home.css */
.home {
    background-image: url('../../assets/background_mytreevel2.png'); /* Adjust the path as necessary */
    background-size: cover; /* Cover the entire screen */
    background-position: center; /* Center the background image */
    background-repeat: no-repeat; /* Prevent background from repeating */
    background-attachment: fixed; /* Prevent background from moving when scrolling */
    overflow: hidden; /* Prevent any scrolling */
    /* height: 100vh;  */
    display: flex; /* Use flexbox for centering content */
    flex-direction: column;
    padding: 50px 0px; 
    /* justify-content: center; 
    align-items: center;  */
    color: white; /* Optional: Set text color to white for visibility */
    /* opacity: 0.5; */
    width: 100%;
}

h1 {
    display: flex; /* Make the h1 a flex container */
    justify-content: center; /* Center text horizontally */
    align-items: center; /* Center text vertically */
    color: #333;

}

.image-container {
    display: flex; /* Use flexbox to align images next to each other */
    flex-direction: row;
    /* justify-content: space-evenly;  */
    margin-bottom: 20px; /* Space between images and download button */
    align-items: center;
}

.mytreevel-container, .mygreendiet-container{
    display: flex;
    flex-direction: column;
    justify-content: center; /* Vertically center the content */
    align-items: center; /* Horizontally center the content */
    width: 50%; /* Set equal width for both containers */
    margin-top: 20px;


    /* margin: 0 auto; */
}

.image {
    width: 200px; /* Set a fixed width for the images */
    height: auto; /* Maintain aspect ratio */
    /* margin: 0 10px; */
    margin: 0 auto;
    margin-top: 10px;
}

.download-logos {
    display: flex; /* Use flexbox to align logos next to each other */
    justify-content: center; /* Center logos horizontally */
    margin-top: 20px; /* Space between phone image and logos */
}

.download-logo {
    width: 200px; /* Set a fixed width for the logos */
    height: auto; /* Maintain aspect ratio */
    margin: 10px 10px; /* Space between logos */
}

@media (max-width: 768px) {
    .image-container {
        flex-direction: column; /* Stack images vertically on smaller screens */
    }

    .image, .download-logo {
        width: 150px; /* Reduce image size for mobile */
    }

    .download-button {
        width: 80%; /* Make button wider on mobile */
        text-align: center; /* Center text in button */
    }
}
